@import '../../styles/variables';

.language-switcher {
    .language-toggle {
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        img {
            width: 32px;
            height: 32px;
        }
    }

    .language-name-selector {
        color: $color-medium-grey;
    }

    .custom-dropdown-menu {
        inset: 20px auto auto -25px !important;
        border-radius: 0 0 32px 32px;
        background-color: $color-dark-grey;
        padding: 28px 35px 28px 32px;
    }

    .dropdown-menu {
        .dropdown-item {
            display: flex;
            align-items: center;
            padding: 0.5rem 1rem;
            font-weight: 700;
            color: $color-muted-grey;

            img {
                margin-right: 8px;
                width: 20px;
                height: 20px;
            }

            .native-name {
                color: $color-muted-grey;
            }

            .english-name {
                color: $color-medium-grey;
                margin-left: 5px;
            }

            &:hover {
                background-color: transparent;
            }

            &.selected {
                color: $color-white !important;

                .native-name, .english-name {
                    color: $color-white !important;
                }
            }
        }
    }

    .dropdown-toggle::after {
        display: none;
    }

    button {
        background: none;
        &:hover, &:focus, &.show, &:first-child:active {
            background-color: transparent;
        }
    }
}
