@import '@stillfront/stillfront-component-library/dist/main.scss';
@import '../../styles/variables';

.position-relative {
  position: relative;
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color:#ced4da;

  &:focus {
    border-color:#ced4da;
  }
}

  .form-content {
    display: flex;
    flex-direction: column;
    gap: $spacing-3xl; 

    .input-wrapper {
      display: flex;
      flex-direction: column;

      .error-message,
      .token-error-message {
          margin-top: $spacing-xs;
      }
  }

    .submit-btn {
      align-self: center;
      width: auto;
    }

    @media (max-width: 576px) {
      padding: $spacing-xs;
    }
}
  
.form-control-feedback {
  width: 100%;
  text-align: left;
  margin-top: $spacing-xs;
}

.invalid-feedback {
  text-align: left;
  color: #bf4242;
}

.input-group-text {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-right: none;
  border-radius: 8px 0 0 8px;
  background: white;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  background: #F2F2F2;
}

@media (max-width: $breakpoint-sm) {   
  .input-group {
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }

  .email-input,
  .submit-btn {
    max-width: 100%;
  }
}

.label-text {
  margin-top: $spacing-md;
}

.email-input {
  width: 100%;
  border-radius: 0 8px 8px 0 !important;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
}

.email-input:focus {
  box-shadow: none !important;
  border-color: #ced4da;
}


.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url(../../assets/images/error.png);
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url(../../assets/images/tick.png);
}

.email-icon {
  width: 20px;
  height: 20px;
}

.error-message {
  margin-top: $spacing-2xs;
  text-align: left;
  color: $color-error-medium;
  font-size: 12px;
}

.token-error-message {
  text-align: left;
  color: $color-error-medium;
  font-size: 12px;

  @media (max-width: $breakpoint-sm) {
      margin-left: $spacing-md;
    }

    @media (min-width: $breakpoint-sm) {
      margin-left: $spacing-xl;
    }
}
