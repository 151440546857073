@import '@stillfront/stillfront-component-library/dist/main.scss';
@import '../../styles/variables';

.consent-container {
  text-align: left;
}

.text-link {
  cursor: pointer;
  color: $color-black;
}

.form-check-input[type=checkbox] {
  border-radius: 2px;
}

input[type=checkbox]:checked {
  background-color: $color-check-green;
  border-color: $color-check-green;
  color: $color-black;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: $color-dark-grey;
  color: $color-black;
}
  