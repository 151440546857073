@import '@stillfront/stillfront-component-library/dist/main.scss';
@import '../../styles/variables';

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $color-light-grey;
    overflow-y: auto;

    @media (max-width: 768px) {
        height: 100vh;
        padding: 0;
    }
}

.auth-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    margin-bottom: $spacing-lg;
    text-align: center;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 100%;
        max-width: none;
        padding: 0;
        border-radius: 0;
        height: 100vh;
        margin-bottom: 0;
    }

    .auth-banner-placeholder {
        width: 100%;
        background: linear-gradient(135deg, $color-light-grey, $color-medium-grey); // Default background
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .auth-banner-image {
            
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;

            &.loaded {
                opacity: 1;
            }
        }

        @media (max-width: 768px) {
            .auth-content {
                padding: $spacing-sm;
            }
        
            .auth-header {
                padding: $spacing-sm;
            }
        }
    }

    @keyframes shimmer {
        from {
            background-position: -200% 0;
        }
        to {
            background-position: 200% 0;
        }
    }
}

.auth-header {
    background-color: $color-black;
    color: $color-white;
    border-radius: 32px 32px 0 0;
    padding: $spacing-md;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .auth-title {
        color: $color-white;
        flex-grow: 1;
        text-align: center;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        padding: $spacing-md;
        border-radius: 0;
        font-family: ESBuildBold, sans-serif;
    }
}



.auth-content {
    padding: $spacing-md;
    background-color: white;
    border-radius: 0 0 32px 32px;

    @media (max-width: 768px) {
        border-radius: 0;
        flex-grow: 1; // Allows the content to expand within the container
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
