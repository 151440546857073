@import '@stillfront/stillfront-component-library/dist/main.scss';
@import '../../styles/variables';

.btn-link {
        color: map-get(map-get($theme-colors, 'light'), 'text-primary');
        font-size: 14px;
        font-weight: 700;
}
.otp-form {
    text-align: right;
}

// TODO: Update to the component from CL
.MuiOtpInput-Box {
    gap: $spacing-2xs !important;

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        gap: calc($spacing-2xs + ((16 - 4) * ((100vw - $breakpoint-xs) / (576 - 320)))) !important; // Formula to gradually increase the gap
    }

    @media (min-width: $breakpoint-sm) {
        gap: $spacing-sm !important;
    }
}
.MuiInputBase-input, .MuiOutlinedInput-input {
    font-size: 16px !important;
    font-weight: bold !important;

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        font-size: calc(16px + ((24 - 16) * ((100vw - $breakpoint-xs) / (576 - 320)))) !important;
        padding: 16px 6px !important;
    }

    @media (min-width: $breakpoint-sm) {
        font-size: 24px !important;
    }
}

.otp-error .MuiInputBase-input,
.otp-error .MuiOutlinedInput-input {
    border: 2px solid $color-error-medium;
    border-radius: $spacing-2xs;

    @media (max-width: $breakpoint-sm) {
        font-size: 16px !important;
        padding: 16.5px 6px;
    }
}


.error-message {
    color: $color-error-medium;

    .spacer {
        flex: 1;
        visibility: hidden;
    }
}

.resend-message {
    color: $color-success-green;
    text-align: left;
    font-size: 12px;
}

.resend-code-container {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-xs;
}

.label-text {
    margin-top: $spacing-md;
}

.email-text {
    word-wrap: break-word;
}

.spinner-position {
    align-self: center;
}
