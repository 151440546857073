// SCSS variables (colors, fonts, spacing, etc.)

// Color variables
$color-black: #000000;
$color-white: #FFFFFF;
$color-dark-green: #0A240F;
$color-light-green: #E1FA73;
$color-success-green: #185C00;
$color-check-green: #289900;
$color-error-red: #A80000;
$color-error-medium: #BE4040;
$color-light-red: #EB0000;
$color-dark-red: #52000F;
$color-light-red: #FF6446;
$color-dark-purple: #241236;
$color-light-purple: #8291FF;
$color-dark-grey: #262B30;
$color-medium-grey: #6F7277;
$color-muted-grey: #BDBFC0;
$color-light-grey: #F2F2F2;
$border-color-light: #262B30;
$border-color-dark: #F2F2F2;
$border-color-grey: #7D8083;

// Theme-specific color variables
$theme-colors: (
    light: (
      //border  
      border-primary: rgba($border-color-light, 0.4),
        
      // text
      text-primary: $color-black,
      text-secondary: rgba($color-black, 0.7),
      text-inverted: $color-white,
      text-light-green: $color-dark-green,
      text-light-red: $color-dark-red,
      text-light-purple: $color-dark-purple,
      text-light-grey: $color-dark-grey,
      
      // background
      bg-base: $color-white,
      bg-surface: $color-light-grey,
      bg-dark-green: $color-light-green,
      bg-dark-red: $color-light-red,
      bg-dark-purple: $color-light-purple,
      bg-dark-grey: $color-light-grey,

      // actions
      action-primary: $color-black,
      action-primary-hovered: rgba($color-black, 0.6),
      action-primary-pressed: $color-dark-grey,
      action-secondary: $color-dark-grey,
      action-secondary-hovered: $color-dark-grey,
      action-secondary-pressed: $color-black,   
      action-outlined: rgba($color-dark-grey, 0.6),
      action-outlined-hovered: rgba($color-dark-grey, 0.8),  
      action-outline-pressed: $color-dark-grey,
    ),
    dark: (
       //border  
       border-primary: rgba($border-color-dark, 0.4),
        
       // text
       text-primary: $color-white,
       text-secondary: rgba($color-white, 0.7),
       text-inverted: $color-black,
       text-light-green: $color-light-green,
       text-light-red: $color-light-red,
       text-light-purple: $color-light-purple,
       text-light-grey: $color-light-grey,
       
       // background
       bg-base: $color-black,
       bg-surface: $color-dark-grey,
       bg-dark-green: $color-dark-green,
       bg-dark-red: $color-dark-red,
       bg-dark-purple: $color-dark-purple,
       bg-dark-grey: $color-dark-grey,
 
       // actions
       action-primary: $color-white,
       action-primary-hovered: rgba($color-white, 0.6),
       action-primary-pressed: $color-light-grey,
       action-secondary: $color-light-grey,
       action-secondary-hovered: $color-white,
       action-secondary-pressed: $color-dark-grey,   
       action-outlined: rgba($color-light-grey, 0.6),
       action-outlined-hovered: rgba($color-light-grey, 0.8),  
       action-outline-pressed: $color-light-grey,
    )
  );

// Breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 576px;
$breakpoint-lg: 1440px;

// Base font size for each breakpoint
$font-sizes-lg: (
  0: 16px,
  1: 24px,
  2: 36px,
  3: 54px,
  4: 80px,
  5: 122px
);

// Font sizes for small screens
$font-sizes-sm: (
  0: 14px,
  1: 18px,
  2: 20px,
  3: 28px,
  4: 40px,
  5: 56px
);

//Font sizes for body

